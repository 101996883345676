<template>
  <div :style="`display: flex; align-items: center; padding: 7px 33px; background-color: ${themeColor.background}; margin-bottom: 30px; justify-content: ${isShowTryEown ? 'space-between' : 'center'};`">
    <div style="display: flex;">
      <a :href="`https://eown.io?${queryParams}`" target="_blank">
        <img v-if="['light'].includes(theme)" src="/logo.svg" style="width: 40px; height: 40px;">
        <img v-else src="/logo-white.svg" style="width: 40px; height: 40px;">
      </a>
      <a :href="`https://eown.io?${queryParams}`" target="_blank" :style="`margin: 0 0 0 12px; font-size: 12px; font-family: 'Montserrat', sans-serif; font-weight: 400; letter-spacing: 0.048px; color: ${themeColor.color}; display: inline-flex; align-items: center; text-decoration: none;`">
        Built with
        <img src="/images/branding/favorite.png" style="width: 16px; height:auto; margin: 0 10px">
        on EOwn
      </a>
    </div>
    <a
      v-if="isShowTryEown"
      :href="`https://stream-studio.eown.io/sign-up?${queryParams}`"
      target="_blank"
      :style="`font-size: 12px; font-family: 'Montserrat', sans-serif; font-weight: 400; letter-spacing: 0.048px; color: ${themeColor.color}; text-decoration: none;`"
    >
      Try EOwn for Free
    </a>
  </div>
</template>

<script>
export default {
  name: 'MainBranding',

  props: {
    refName: {
      type: String,
      default: ''
    },
    isShowTryEown: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: 'primary'
    }
  },

  computed: {
    queryParams() {
      const query = 'utm_source=eown-content-stream&utm_medium=redirect&utm_campaign=bottom-page-link'
      if (this.refName) {
        return `ref=${this.refName}&${query}`
      }
      return query
    },
    themeColor() {
      switch (this.theme) {
        case 'primary':
          return { background: '#2963EF', color: 'white' }
        case 'light':
          return { background: 'transparent', color: '#2963EF' }
        default:
          return {}
      }
    }
  }
}
</script>

<style>

</style>
